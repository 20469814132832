import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../../dataProvider/withDataHOC';
import {numberOrNull} from '../../../../lib/number';
import {merge} from '../../../../lib/object';
import {isSelected as isSelectedLib, setSelected as setSelectedLib} from '../../../../lib/tableCheckbox';
import {CartLine} from '../../../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../../../constants/globalData';
import {createFetchCartEditItem} from '../../../../backend/apiCalls';
import { notification } from 'antd';

/**
 * @dusan
 */

const cartContentLine = (Decorator) => {
    return withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(class extends React.PureComponent {
        static propTypes = {
            data: CartLine.isRequired,
            reload: PropTypes.func.isRequired,
            customerId: PropTypes.number,
            selectedItemsIds: PropTypes.arrayOf(PropTypes.number.isRequired),
            setSelected: PropTypes.func,
            [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
        };

        editItem = (queryParams) => {
            const {data, reload, customerId} = this.props;
            const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
            fetchHandler(createFetchCartEditItem(),
                merge(
                    {
                        id_item: data.id_item,
                        id_customer: customerId,
                    },
                    queryParams
                ),
                null,
                reload,
                (error) => {
                    notification['error']({ 
                        message: error.message,
                        duration: 10,
                    });
                }
            );
        };

        onRequestedAtChange = (requestedAt) => {
            this.editItem({requested_at: requestedAt});
        };

        onNoticeChange = (newNotice) => {
            this.editItem({customer_notice: newNotice});
        };

        onDealerNoticeChange = (newNotice) => {
            this.editItem({dealer_notice: newNotice});
        };

        onQuantityChange = (newQuantity) => {
            const {reload} = this.props;
            const newQuantityNumber = numberOrNull(newQuantity);
            if (newQuantityNumber != null && newQuantityNumber > 0) {
                this.editItem({package_quantity: newQuantity});
            }
            else {
                reload();
            }
        };

        onPriceChange = (newPrice) => {
            const newPriceChecked = numberOrNull(newPrice);
            this.editItem({package_price: newPriceChecked});
        };

        onSequenceChange = (newSequence) => {
            const {reload} = this.props;
            const newSequenceNumber = numberOrNull(newSequence);
            if (newSequenceNumber != null && newSequenceNumber > 0) 
                this.editItem({sequence: newSequenceNumber});
            else
                reload();
        };

        onPackageTypeChange = (newPackageTypeId) => {
            this.editItem({id_package_type: newPackageTypeId});
        };

        onStockChange = (newStockId) => {
            this.editItem({id_stock: newStockId});
        };

        onAtomicChange = (newAtomic) => {
            this.editItem({is_atomic: newAtomic});
        };

        onSelect = (val) => {
            const {data, setSelected, selectedItemsIds} = this.props;
            if(setSelected != null)
                setSelected(setSelectedLib(val, data.id_item, selectedItemsIds));
        }

        render() {
            const {data, setSelected, selectedItemsIds, ...props} = this.props;
            const isSelected = isSelectedLib(data.id_item, selectedItemsIds);
            return <Decorator
                {...props}
                data={data}
                onPriceChange={this.onPriceChange}
                onQuantityChange={this.onQuantityChange}
                onRequestedAtChange={this.onRequestedAtChange}
                onNoticeChange={this.onNoticeChange}
                onDealerNoticeChange={this.onDealerNoticeChange}
                onSequenceChange={this.onSequenceChange}
                onPackageTypeChange={this.onPackageTypeChange}
                onStockChange={this.onStockChange}
                onAtomicChange={this.onAtomicChange}
                isSelected={isSelected}
                onSelect={setSelected != null ? this.onSelect : null}
            />;
        }
    })
}

export default cartContentLine;