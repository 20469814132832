import React from 'react';
import PropTypes from 'prop-types';
import RemoveCartItem from './RemoveCartItem';
import AvailabilityIcon from './AvailabilityIcon';
import cartContentLine from './CartContentLine';
import InputQuantity from '../../../project/InputQuantity';
import TaxedPriceDisplay from '../../../project/TaxedPriceDisplay';
import InputUnitPrice from '../../../project/InputUnitPrice';
import Link from '../../../navigation/Link';
import {formatPercentage, formatNumber, formatQuantity} from '../../../../lib/formatting';
import {QUERY_PARAMS, ROUTES} from '../../../../constants/navigation';
import {CartLine} from '../../../../constants/propTypesDefinitions';
import DatePicker from '../../../general/DatePicker';
import RightsWrapper from '../../../sessionProvider/RightsWrapper';
import {RIGHTS} from '../../../../constants/Rights';
import {Trans, t} from '@lingui/macro';
import InputTextArea from '../../../general/InputTextArea';
import TileAttribute from '../../../general/TileAttribute';
import Image from '../../../general/Image';
import { currentDate} from '../../../../lib/date';
import StockOptionsSelect from '../../../project/StockOptionsSelect';
import UnitPrice from '../../../general/UnitPrice';
import SpecialTransportIcon from '../../../project/SpecialTransportIcon';


/**
 * @fero
 */

class CartContentTile extends React.PureComponent {
    static propTypes = {
        data: CartLine.isRequired,
        reload: PropTypes.func.isRequired,
        customerId: PropTypes.number,
        onQuantityChange: PropTypes.func.isRequired,
        onPriceChange: PropTypes.func.isRequired,
        onRequestedAtChange: PropTypes.func.isRequired,
        onNoticeChange: PropTypes.func.isRequired,
        onDealerNoticeChange: PropTypes.func.isRequired,
        onStockChange: PropTypes.func.isRequired,
    };

    render() {
        const {
            customerId, data, reload, onQuantityChange, onPriceChange, onStockChange, 
            onRequestedAtChange, onNoticeChange, onDealerNoticeChange, useVAT
        } = this.props;
        return <div className="tile-wrapper">
            <div className="p-2 d-flex">
                <Link
                    className="text-dark"
                    to={ROUTES.PRODUCT_DETAILS}
                    queryParams={{[QUERY_PARAMS.ID_PRODUCT]: data.id_product}}
                >
                    <Image 
                        src={data.thumbnail}
                        wrapperClassName="cart-content-thumbnail-wrapper"
                        className="cart-content-thumbnail"
                    />
                </Link>
                <div>
                    <Link
                        className="text-dark"
                        to={ROUTES.PRODUCT_DETAILS}
                        queryParams={{[QUERY_PARAMS.ID_PRODUCT]: data.id_product}}
                    >
                        <h4 className="px-2">{data.designation + ' (' + data.manufacturer + ')'}</h4>
                    </Link>
                    { data.order_code != null ? 
                        <TileAttribute
                            title={<Trans>Obj. č.</Trans>}
                            value={data.order_code}
                        /> : null
                    }
                    <RightsWrapper from={RIGHTS.WHOLESALE_CUSTOMER}>
                        <TileAttribute
                            title={<Trans>Vlastné ozn.</Trans>}
                            value={data.customer_code}
                        />
                    </RightsWrapper>
                    <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                        <TileAttribute
                            title={<Trans>Dostupnosť</Trans>}
                            value={<div>
                                <AvailabilityIcon 
                                    isAvailable={data.is_available}
                                />
                                <StockOptionsSelect
                                    value={data.id_stock}
                                    onChange={onStockChange}
                                    label={data.stock}
                                    productId={data.id_product}
                                    customerId={data.id_customer}
                                    quantityUnits={data.quantity_units}
                                />
                            </div>}
                        />
                    </RightsWrapper>
                    <RightsWrapper from={RIGHTS.MARKETING}>
                        <TileAttribute
                            title={<Trans>Dostupnosť</Trans>}
                            value={formatNumber(data.quantity_available) + ' ' + data.quantity_units}
                        />
                    </RightsWrapper>
                </div>
                <div className="d-flex flex-grow-1 justify-content-end">
                    <RemoveCartItem reload={reload} cartLine={data}/>
                </div>
            </div>
            <div className="d-flex">
                <TileAttribute
                    className="half-size-width"
                    title={<Trans>Množstvo</Trans>}
                    value={<InputQuantity
                        size="small"
                        className="text-right"
                        value={Number(data.package_quantity)}
                        onChange={onQuantityChange}
                        unit={data.package_type}
                    />}
                />
                <TileAttribute
                    className="half-size-width"
                    title={<Trans>Jedn. cena</Trans>}
                    value={<React.Fragment>
                        <RightsWrapper to={RIGHTS.WORKER}>
                            <UnitPrice price={data.package_price} units={data.package_type} currency={data.id_currency}/>
                        </RightsWrapper>
                        <RightsWrapper from={RIGHTS.DEALER}>
                            <InputUnitPrice
                                size="small"
                                value={data.package_price}
                                isSpecial={data.is_special == 1}
                                hasError={data.package_price == null}
                                onChange={onPriceChange}
                                unit={data.package_type}
                                currency={data.id_currency}
                            />
                        </RightsWrapper>
                    </React.Fragment>}
                />
            </div>
            { data.only_bulk_purchase > 0 ? 
                <RightsWrapper from={RIGHTS.MARKETING}>
                    <TileAttribute
                        title={<Trans>Minimálny nákup</Trans>}
                        value={formatQuantity(data.quantity_bulk, data.quantity_units)}
                        className="color-red"
                        labelClassName="color-red"
                    />
                </RightsWrapper> : 
                null
            }
            <TileAttribute
                className="two-third-width"
                title={<Trans>Spolu</Trans>}
                value={
                    <TaxedPriceDisplay
                        linePrice={data.line_price}
                        totalPrice={data.total_price}
                    />
                }
            />
            { useVAT ? 
                <TileAttribute
                    className="third-width"
                    title={<Trans>DPH</Trans>}
                    value={formatPercentage(data.tax_rate)}
                />
                : null
            }
            <TileAttribute
                className="text-nowrap"
                title={<Trans>Doručiť najskôr dňa</Trans>}
                value={<DatePicker
                    size="small"
                    className="full-size-width"
                    value={data.requested_at}
                    min={currentDate()}
                    onChange={onRequestedAtChange}
                />}
            />
            <TileAttribute
                className="full-size-width flex-wrap"
                title={<Trans>Poznámka</Trans>}
                value={<InputTextArea
                    size="small"
                    value={data.customer_notice}
                    onChange={onNoticeChange}
                />}
            />
            <RightsWrapper from={RIGHTS.MARKETING}>
                <TileAttribute
                    className="full-size-width flex-wrap"
                    title={<Trans>Interná poznámka</Trans>}
                    value={<InputTextArea
                        size="small"
                        value={data.dealer_notice}
                        onChange={onDealerNoticeChange}
                    />}
                />
            </RightsWrapper>
            <SpecialTransportIcon flag={data.special_transport} className="px-2"/>
        </div>;
    }
}

export default cartContentLine(CartContentTile);