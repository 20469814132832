import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../general/Table';
import CartContentRow from './cartContentTable/CartContentRow';
import PriceWhole from '../../project/PriceWhole';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {navigateToParametrized, getQueryParam, getQueryParamNumber} from '../../../lib/url';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {RIGHTS} from '../../../constants/Rights';
import {Trans, t} from '@lingui/macro';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import CartContentTile from './cartContentTable/CartContentTile';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import { numberOrNull } from '../../../lib/number';
import { Checkbox } from 'antd';
import { getInputOnChangeEventChecked } from '../../../lib/project';
import { CartCurrent } from '../../../constants/propTypesDefinitions';
import Price from '../../general/Price';


/**
 * @fero
 */

class CartContentTable extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        selectedItemsIds: PropTypes.arrayOf(PropTypes.number.isRequired),
        setSelected: PropTypes.func,
        [GLOBAL_DATA.CART_CURRENT]: CartCurrent.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,

    };

    setOrdering = (newOrder) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.CART_ORDER_BY]: newOrder,
            [QUERY_PARAMS.CART_SCROLL]: 0,
        });
    };

    setLimit = (newLimit) => {
        const {location} =  this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.CART_LIMIT]: newLimit
        });
    }

    setOffset = (newOffs) => {
        const {location} =  this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.CART_OFFSET]: newOffs,
            [QUERY_PARAMS.CART_SCROLL]: 0,
        });
    }

    areAllSelected = () => {
        const {[GLOBAL_DATA.CART_CURRENT]: cartCurrent, selectedItemsIds} = this.props;
        //naive testing because of performance
        return (selectedItemsIds == null || cartCurrent == null || cartCurrent.lines_count == selectedItemsIds.length);
    };

    render() {
        const {
            location, setSelected, selectedItemsIds,
            [SESSION_ATTRIBUTES.RIGHTS]: rights,
            [SESSION_ATTRIBUTES.SETTINGS]: settings,
            [GLOBAL_DATA.RELOAD_DATA]: reload,
            [GLOBAL_DATA.CART_CURRENT]: cartCurrent 
        } = this.props;
        const orderBy = getQueryParam(location, QUERY_PARAMS.CART_ORDER_BY);
        const limit = getQueryParamNumber(location, QUERY_PARAMS.CART_LIMIT);
        const offset = getQueryParamNumber(location, QUERY_PARAMS.CART_OFFSET);
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ID_CUSTOMER);
        const offsetChecked = offset != null ? offset : 0;
        const limitChecked = limit != null ? limit : (numberOrNull(process.env.DEFAULT_CART_RESULT_LIMIT) || 100);
        const lines = Array.isArray(cartCurrent.lines) ? cartCurrent.lines.slice(offsetChecked, offsetChecked + limitChecked) : [];
        const specialTransportLines = lines.filter(ln => ln.special_transport != 0);
        const amountToFreeTransport = specialTransportLines.length == 0 && cartCurrent.free_transport_limit != null ? Number(cartCurrent.free_transport_limit) - Number(cartCurrent.sum_netto) : null;
        const useVAT = settings.use_vat;

        return <Table
            className={rights >= RIGHTS.MARKETING ? "cart-content-table-dealer" : "cart-content-table-customer"}
            BodyRow={CartContentRow}
            BodyTile={CartContentTile}
            data={lines}
            customerId={customerId}
            notScrollable={true}
            orderBy={orderBy}
            changeOrder={this.setOrdering}
            limit={limit}
            offset={offset}
            pageSize={numberOrNull(process.env.DEFAULT_CART_PAGE_SIZE) || 100}
            changeLimit={this.setLimit}
            changeOffset={this.setOffset}
            totalCount={cartCurrent.lines_count}
            selectedItemsIds={selectedItemsIds}
            setSelected={setSelected}
            useVAT={useVAT}
            reload={() => {
                reload([GLOBAL_DATA.CART_CURRENT])
            }}
            footer={{
                node: <div className="d-flex justify-content-between">
                    <div className="color-red">
                        { amountToFreeTransport != null ? 
                            <h4>
                                { amountToFreeTransport > 0.0 ? 
                                    <Trans>Nakúpte ešte za <Price price={amountToFreeTransport} currency={cartCurrent.id_currency} withVAT={false}/> a získate dopravu zadarmo.</Trans>
                                    : <Trans>Doprava zadarmo (okrem neštandardných a nadrozmerných tovarov).</Trans>
                                }
                            </h4>
                            : null
                        }
                        { specialTransportLines.length > 0 ?
                            <h4><Trans>Pozor, niektoré z položiek v košíku vyžadujú neštandardnú dopravu.</Trans></h4>
                            : null
                        }
                    </div>
                    <PriceWhole linesCount={cartCurrent.lines.length} prices={cartCurrent} currency={cartCurrent.id_currency}/>
                </div>,
                heightRem: 5.5
            }}
            colDefs={[
                setSelected != null ? 
                {
                    class: 'cart-content-table-checkbox',
                    headerCell: <div className="text-center">
                        <Checkbox
                            checked={this.areAllSelected()}
                            onChange={(e) => {
                                const selected = getInputOnChangeEventChecked(e);
                                if(selected) {
                                    setSelected(null)
                                } else {
                                    setSelected([]);
                                }
                            }}
                        />
                    </div>
                } : null,
                {
                    headerCell: <Trans>Č.</Trans>,
                    orderCol: 'sequence',
                    class: 'cart-content-table-sequence'
                },
                {
                    headerCell: <Trans>Názov</Trans>,
                    orderCol: 'designation',
                    class: 'cart-content-table-col-designation'
                },
                {
                    headerCell: <Trans>Výrobca</Trans>,
                    orderCol: 'manufacturer',
                    class: 'cart-content-table-col-manufacturer'
                },
                {
                    headerCell: <Trans>Množstvo</Trans>,
                    orderCol: 'package_quantity',
                    class: 'cart-content-table-col-quantity'
                },
                {
                    headerCell: <Trans>Skladom</Trans>,
                    orderCol: 'is_available',
                    descFirst: true,
                    class: 'cart-content-table-col-availability'
                },
                {
                    headerCell: <Trans>Jednot. cena</Trans>,
                    orderCol: 'unit_price',
                    class: 'cart-content-table-col-unit-price'
                },
                {
                    headerCell: useVAT ? <Trans>Spolu bez DPH</Trans> : <Trans>Spolu</Trans>,
                    orderCol: 'line_price',
                    class: 'cart-content-table-col-netto-price'
                },
                useVAT ? {
                    headerCell: <Trans>Daň</Trans>,
                    class: 'cart-content-table-col-vat'
                } : null,
                useVAT ? {
                    headerCell: <Trans>Suma s DPH</Trans>,
                    orderCol: 'total_price',
                    class: 'cart-content-table-col-brutto-price'
                } : null,
                {
                    headerCell: <Trans>Požadovaný termín dodania</Trans>,
                    orderCol: 'requested_at',
                    class: 'cart-content-table-col-requested-at'
                },
                {
                    headerCell: <Trans>Poznámka</Trans>,
                    class: 'cart-content-table-col-customer-notice'
                },
                {
                    headerCell: <Trans>Interná poznámka</Trans>,
                    class: 'cart-content-table-col-dealer-notice',
                    rightsFrom: RIGHTS.MARKETING,
                },
                {
                    class: 'cart-content-table-col-actions'
                },
            ]}
        />;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.RIGHTS, SESSION_ATTRIBUTES.SETTINGS])(
    locationHOC(
        withDataHOC([GLOBAL_DATA.CART_CURRENT, GLOBAL_DATA.RELOAD_DATA])(CartContentTable)
    )
);